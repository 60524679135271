const numeral = require("numeral");

export function formatNumber(value) {
  return numeral(value).format("0,0");
}

export function formatCurrencyRaw(value) {
  if (parseFloat(value)) {
    return numeral(value).format("00.00");
  }
  return numeral(0).format("00.00");
}

export function formatCurrency(value) {
  if (parseFloat(value)) {
    return numeral(value).format("$0,0.00");
  }
  return numeral(0).format("$0,0.00");
}

export function formatPhoneNo(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumber;
}
