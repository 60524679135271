import React from "react";
import Alert from "@material-ui/lab/Alert";

function Error({ message, top = 10, bottom = 10 }) {
    if (!message) {
        return null;
    }
    return (
        <Alert
            severity="error"
            style={{
                marginTop: top,
                marginBottom: bottom,
                width: "100%"
            }}
        >
            {message}
        </Alert>
    );
}

export default Error;
